import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import $ from 'jquery';
import { JWTService } from '../auth/jwt.service'
import { LoginService } from '../login/shared/login.service'
import { Route, Router, Routes } from '@angular/router';
import { LoaderService } from '../shared/loader.service';
import { UtilsService } from '../shared/utils.service';
import { Token } from '../auth/token.model';
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { Notificacao } from '../shared/model/notificacao.model';
import { SharedService } from '../shared/shared.service';
import 'rxjs/add/observable/interval';
import { Observable } from '../../../node_modules/rxjs';
import { AUTHORITIES } from '../auth/groups.constants';
import { ROUTES } from '../app.routes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [LoginService, JWTService, UtilsService],
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  constructor(
    private jwtService: JWTService,
    private loginService: LoginService,
    private router: Router,
    private utils: UtilsService,
    private loaderService: LoaderService,
    private eventEmitterService: EventEmitterService,
    private sharedService: SharedService,
    private elementRef :ElementRef,
    private renderer :Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    let token = this.jwtService.getObject('auth_token') as Token;
    try {
      token = this.jwtService.decode(token);
      this.gruposUsuario = token.authorities;
      if (this.sharedService.hasPermission(this.jwt, [AUTHORITIES.RETAGUARDA_MULTICOBRANCA])) { // Retira Icone Cadastro - Jira317 (Multicobranca)
        this.arr = this.arr.filter((route) => {
          return route.path != 'cadastros'
        });
      }
    } catch (e) { }
    this.allowAccessShowMenu();
  }

  private recuperarMenusPrincipais() {
    let menusPrincipais = [];

    this.arr.forEach(menu => {
      if (menu.data.show && this.allowMenu(menu.path)) {
        menusPrincipais.push(menu.data.title);
      }
    });
    return menusPrincipais;
  }

  @Input() showMenu: boolean;
  @Input() routeChild: Route;
  @Input() route: Route;

  showChildren: boolean;
  routeChildren: Route;
  subMenuChildren: boolean = false;

  gruposUsuario: string[];
  arr: Routes = ROUTES;
  allowedRoutes: string[] = [];
  arrChildren: any[] = [];
  eps: any;
  jwt: Token;
  parentRoute: {
    icon: null,
    name: null,
    parent: string
  }

  show: boolean = false;

  menus_4: boolean = false;
  menus_5: boolean = false;

  nome: string;
  data: Date;

  week: string[] = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-Feira', 'Sabado'];
  month: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  today: Date = new Date();
  dayOfWeek: string;
  dayOfMonth: string;
  monthOfYear: string;

  numeroDeNotificacoes: number = 0;
  notificacoes: Notificacao[] = [];

  ngOnInit() {
    const userData = this.jwtService.getObject('auth_token') as Token;
    try {
      this.nome = this.formatarNome(userData.apelido);
    } catch (e) {
      this.nome = '';
    }
    $('.content-wraper').addClass('col-12');
    $('.content-wraper').addClass('pl-5');
    $('.content-wraper').addClass('pb-3');
    $('.content-wraper').addClass('ml-5');
    $('.content-fluid').addClass('wraper');
    $('.content-wraper').addClass('float-right');
    $('.content-wraper').removeClass('pl-3');
    $('.content-wraper').removeClass('col-6');
    $('.content-wraper').removeClass('ml-0');

    this.dayOfWeek = this.week[this.today.getDay()];
    this.monthOfYear = this.month[this.today.getMonth()];
    let day = this.today.getDate();
    this.dayOfMonth = day <= 9 ? '0' + day : day.toString();

    this.jwt = this.jwtService.getObject('auth_token');
    this.listar();

    this.eps = this.jwtService.getObject('_EPS');
    if (this.eps == 1) {
      this.expandMenu();
    }
  }

  formatarNome(nome: string): string {
    let pos: number = 0;
    if ((pos = nome.trim().lastIndexOf(" ")) !== -1) {
      return nome.substring(0, pos);
    }
    if (nome.length >= 10) {
      return nome.substring(0, 10) + "...";
    }
    return nome;
  }

  listar() {
    Observable.interval(1000 * 20).subscribe(x => {
      if (!this.jwtService.loggedIn())
        return;
      this.sharedService.getNotificacoes(this.jwt.idLogin.toString())
        .subscribe(
          (resp: Notificacao[]) => {
            this.notificacoes = resp;
            this.numeroDeNotificacoes = this.notificacoes.length;
          }
        );
    });
  }

  logout() {
    this.loaderService.display(true);
    const token = this.jwtService.getObject('auth_token') as Token;
    if (token && token.access_token) {
      this.loginService.logout(this.jwtService.getObject('auth_token') as Token)
        .subscribe(
          () => {
            this.loaderService.display(false);
            this.eventEmitterService.display(false);
            this.jwtService.removeAllCookies();
            $(".breadcrumb").css({ 'display': 'none' })
            window.location.href='/login';          },
          errors => {
            this.loaderService.display(false);
            this.jwtService.reiniciarSessao();
          }
        );
    } else {
      this.loaderService.display(false);
      this.eventEmitterService.display(false);
      this.jwtService.removeAllCookies();
      $(".breadcrumb").css({ 'display': 'none' })
        window.location.href='/login';
    }

  }

  expandMenu() {
    if (this.show) {
      this.show = false
      $(".container-fluid").css({ 'margin-left': '0px', 'width': '95%' })
      $('.breadcrumb').css({ 'margin-left': '50px', 'width': '95%' });
      $('.breadcrumb').removeClass('openMenu')
      var menuOpen = $('.subMenu-children').hasClass('open');
      if (menuOpen) {
        $('.breadcrumb').css({ 'margin-left': '350px', 'width': '73%' })
      } else {
        $('.breadcrumb').css({ 'margin-left': '50px', 'width': '95%' })
      }
    } else {
      this.show = true;
      $(".container-fluid").css({ 'margin-left': '160px', 'width': '84%' })
      $('.breadcrumb').css({ 'margin-left': '200px', 'width': '84%' })
      $('.breadcrumb').addClass('openMenu');
      var menuOpen = $('.subMenu-children').hasClass('open');
      if (menuOpen) {
        $(".container-fluid").css({ 'margin-left': '500px', 'width': '62%' })
        $('.breadcrumb').css({ 'margin-left': '500px', 'width': '62%' })
      } else {
      }
    }
  }

  closeSubmenu() {
    this.showChildren = false;
  }

  showChildrenChildSubMenu(childSubMenu) {
    if (!childSubMenu.show) {
      childSubMenu.show = true;
    } else {
      childSubMenu.show = false
    }
  }

  allowMenu(path: string) {
    if (this.allowedRoutes.indexOf(path) != -1)
      return true;
    else
      return false;
  }

  showSubMenuChildren(subMenu) {
    if (!subMenu.show) {
      subMenu.show = true;
    } else {
      subMenu.show = false
    }
  }

  setClickedRoute(route: Route) {
    this.route = route;
    if (this.route.path !== 'home' && this.route.path !== 'centralAjuda') {
      this.showSubMenu(this.route);
    } else {
      this.router.navigate([this.route.path]);
      (this.showChildren ? this.showChildren = false : '');
    }

    this.recuperarMarginDivMenu('.' + this.route.data.title);
  }

  recuperarMarginDivMenu(nomeClasseMenu) {
    const divElement: HTMLElement = this.elementRef.nativeElement.querySelector(nomeClasseMenu);
    if(divElement){
      const posicaoMenuPai = divElement.getBoundingClientRect();
      this.colocarPosicaoMenuSubmenu(posicaoMenuPai.left);
    }
  }

  colocarPosicaoMenuSubmenu(posicaoMenuPai){
    this.changeDetectorRef.detectChanges();
    let classeSubmenu = '.contentSubMenu';
    const divElement: HTMLElement = this.elementRef.nativeElement.querySelector(classeSubmenu);
    if(divElement){
        this.renderer.setStyle(divElement,'margin-left',posicaoMenuPai+'px');
    }
  }

  showSubMenu(route: Route) {
    this.arrChildren = [];
    this.showChildren = true;
    if (this.eps != 1) {
      this.showMenu = false;
    }

    this.parentRoute = {
      icon: this.route.data.icon,
      name: this.route.data.title,
      parent: this.route.path
    };
    this.arrChildren = this.route.children;
  }

  checkMenu(menu: any) {
    try {
      return menu.data.show && this.jwtService.checkGroupPermission("/".concat(menu.data.link), this.gruposUsuario)
    } catch (e) {
      return false;
    }
  }

  allowAccessShowMenu() {
    this.arr.forEach(menu => {
      const filhos: any[] = menu.children;

      if (menu.path == 'centralAjuda') {
        if (environment.settings.central_ajuda.enabled) {
          this.allowedRoutes.push(menu.path);
        }
      } else if (filhos && filhos.length > 0) {
        let possuiFilhosAtivos = false;
        filhos.forEach(c => {
          const netos: any[] = c.children;

          if (netos && netos.length > 0) {
            let possuiNetosAtivos = false;
            netos.forEach(n => {
              const bisnetos: any[] = n.children;

              if (bisnetos && bisnetos.length > 0) {
                let possuiBisnetosAtivos = false;
                bisnetos.forEach(b => {
                  if (this.checkMenu(b)) {
                    possuiBisnetosAtivos = true;
                    this.allowedRoutes.push(b.path);
                  }
                });

                if (possuiBisnetosAtivos) {
                  this.allowedRoutes.push(n.path);
                  possuiNetosAtivos = true;
                }
              } else {
                if (this.checkMenu(n)) {
                  possuiNetosAtivos = true;
                  this.allowedRoutes.push(n.path);
                }
              }
            });

            if (possuiNetosAtivos) {
              this.allowedRoutes.push(c.path);
              possuiFilhosAtivos = true;
            }
          } else {
            if (this.checkMenu(c)) {
              possuiFilhosAtivos = true;
              this.allowedRoutes.push(c.path);
            }
          }
        });

        if (possuiFilhosAtivos) {
          this.allowedRoutes.push(menu.path);
        }
      } else {
        if (this.checkMenu(menu)) {
          this.allowedRoutes.push(menu.path);
        }
      }
    });
  }

}
