import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from './shared/login.service';
import { JWTService } from '../auth/jwt.service';
import { Login } from './shared/login.model';
import { Token } from '../auth/token.model';
import { UtilsService } from '../shared/utils.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoaderService } from '../shared/loader.service';
import { MyErrorStateMatcher } from '../shared/error-matcher-input/error-matcher-input.model';
import $ from "jquery"
import { EventEmitterService } from '../shared/event-emiter-service.service';
import { ConstantsMaskModel } from '../shared/constants/constants-mask.model';
import { environment } from 'src/environments/environment';
import { MESSAGES } from '../shared/constants/constants-swal.model';
import { AcessoTemporario } from '../senha/shared/senha.model';
import { SenhaService } from '../senha/shared/senha.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService, JWTService, UtilsService,LoaderService,SenhaService]
})
export class LoginComponent implements OnInit {

  login: Login;
  loginForm: FormGroup;
  cpfControl = new FormControl('', [Validators.required]);
  passControl = new FormControl('', [Validators.required]);
  cpfCnpjMask: ConstantsMaskModel = new ConstantsMaskModel();
  acessoTemporario: AcessoTemporario;
  matcher = new MyErrorStateMatcher();

  captchaChave: string;
  visible: boolean = false;
  changetype: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private jwtService: JWTService,
    private utils: UtilsService,
    private router: Router,
    private loaderService: LoaderService,
    private emitterService: EventEmitterService,
    private senhaService: SenhaService,
    private util: UtilsService) {
      this.captchaChave = environment.settings.captcha.chave


    }


  ngOnInit() {
    this.jwtService.authCheck(true);
    this.emitterService.display(false);
    this.loginForm = this.formBuilder.group({
      username: this.cpfControl,
      password: this.passControl
    });


    console.log(this.captchaChave);
    $(".breadcrumb").css({'display': 'none'});
    $('.content-wraper').addClass('col-6');
    $('.content-wraper').addClass('float-right');
    $('.content-wraper').addClass('pl-3');
    $('.content-wraper').addClass('ml-0');

    $(".container-fluid").removeClass('wraper');
    $(".content-wraper").removeClass('ml-5');
    $('.content-wraper').removeClass('col-12');
    $('.content-wraper').removeClass('pl-5');
    $('.content-wraper').removeClass('pb-3');
    this.acessoTemporario = new AcessoTemporario();
  }

  viewpass(){
    this.visible = !this.visible
    this.changetype = !this.changetype
  }

  signIn(captcha: string) {
    if(this.loginForm.invalid)
      return;
    let model = this.loginForm.value;
    this.loaderService.display(true);

    this.loginService.login(model.username, model.password, 'local', captcha)
      .subscribe(
      (token: Token) => {
        this.loaderService.display(false);
        this.storeAuthToken(token);
        if (token.access_token) {
          this.router.navigate(['home']);
          $(".container-fluid").addClass('wraper');
        } else {
          this.router.navigate(['mfa']);
        }
      },
      errors => {
        this.loaderService.display(false);
        this.isAcessoTemporario(model.username, model.password, errors);
      }
    );
  }

  executeCaptcha() {
    const siteKey = this.captchaChave;
    const container = document.getElementById('captcha-container');

    if (container) {

      container.style.display = 'block';

      if (!container.hasAttribute('data-rendered')) {
        (window as any).turnstile.render(container, {
          sitekey: siteKey,
          callback: (token) => {
            this.signIn(token);
          }
        });

        container.setAttribute('data-rendered', 'true');
      } else {
        (window as any).turnstile.reset();
      }


    } else {
      console.error("Container não encontrado");
    }

  }


  signOut() {
    this.loaderService.display(true);
      this.loginService.logout(this.jwtService.getObject('auth_token') as Token)
        .subscribe(
        () => {
          this.loaderService.display(false);
          this.removeAuthToken();
        },
        errors => {
          this.loaderService.display(false);
          this.removeAuthToken();
          grecaptcha.reset();
        }
      );
  }

  storeAuthToken(token: Token) {
    var dt = new Date();
    dt.setSeconds(dt.getSeconds() + token.expires_in);
    token.expires_in = dt;
    this.jwtService.putObject('auth_token', token, dt);
  }

  removeAuthToken() {
    this.jwtService.removeAllCookies();
    this.router.navigate(['/login']);
  }

  cadastraSenha(cpfParam) {
    this.router.navigate(['/nova-senha'], {
      queryParams: {
        cpf: cpfParam
      }
    });
  }



  isAcessoTemporario(username, password, errorLogin) {
    this.acessoTemporario.cpf = username;
    this.acessoTemporario.senhaProvisoria = password;
    this.acessoTemporario.tipoUsuario = 'FO';
    this.senhaService.acessoTemporario(this.acessoTemporario)
      .subscribe(
        (tmp : AcessoTemporario) => {
            if(tmp.codOperacaoTemporaria === 'PA' || tmp.codOperacaoTemporaria === 'ES'){
              this.cadastraSenha(username);
            }
        },
        errors => {
          this.loaderService.display(false);
          Swal(this.utils.tratarErro(errorLogin), "", MESSAGES.TYPE_ERROR);
        //  grecaptcha.reset();
        }
      );
  }
}
